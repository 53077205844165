import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { ShopLayout } from "../components/ShopLayout";

class CatRoute extends React.Component {
    render() {
        const posts = this.props.data.allMarkdownRemark.edges;

        const products = posts.map(({ node }) => ({
            id: node.id,
            title: node.frontmatter.title,
            src: `/bespoke-orders/category/${node.frontmatter.title}`,
            image: node.frontmatter.featuredImage,
        }));
        const tag = this.props.pageContext.tag;
        const title = this.props.data.site.siteMetadata.title;
        return (
            <>
                <Helmet title={`${tag} | ${title}`} />
                <ShopLayout
                    products={products}
                    title={tag}
                    page={'bespoke-orders/category'}
                    pageContext={this.props.pageContext}
                />
            </>
        );
    }
}

export default CatRoute;

export const catPageQuery = graphql`
    query CatPage($tag: String, $skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___category], order: DESC }
            filter: { frontmatter: { category: { in: [$tag] } } }
            limit: $limit
            skip: $skip
        ) {
            totalCount
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
